import React from "react"
import KudaPayroll from "../../../components/body/pages/en-ng/business/kuda-payroll"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const KudaPayrollPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/payroll/"}
      title="Payroll with Kuda |  Streamline Your Business Payroll Payments | Kuda Business"
      description="Simplify your business finances with Kuda Payroll app. Set up regular monthly payments to staff directly from your Kuda account. Efficient and secure."
    />

    <KudaPayroll />
  </Layout>
)

export default KudaPayrollPage
